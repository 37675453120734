import React from 'react'
import styled from 'styled-components'

const DescWrapp = styled.div`
    width:100%;
    font-weight:400;
    font-size:14px;
    line-height:24px;
    text-align:left;
    color:#7C7C7C;

    @media (max-width: 1440px) {
        font-size:12px;
         line-height:20px;
    }

    @media (max-width: 899.98px) {
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
    }

    @media (max-width: 599.98px) { 
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
    }
`
const Desc = ({ desc, style }) => {
    return (
        <DescWrapp style={{ ...style }}>{desc}</DescWrapp>
    )
}

export default Desc