import React, { useState } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Desc from './Desc'
import Input from './Input'
import Button from './Button'
import axios from 'axios';
import PhoneInput from './PhoneInput'
import CheckInput from './CheckInput'
import { useNavigate } from 'react-router-dom'

const FormWrapper = styled.form`
    max-width:650px;
    border-radius:2px;
    border:1px solid #FFFFFF;
    background-color:#FFFFFF;
    padding:36px 70px;
    display:flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1440px) {
        max-width:600px; 
    }
`

const TitleWrapp = styled.div`
    display:block;
    font-size:37px;
    font-weight:700;
    line-height:42px;
    color:#000000;
    text-align:left;
    width:100%;
    max-width:620px;

    @media only screen and (max-width: 1440px) {
        font-size:28px;
        line-height:33px;
    }
`

const TextWrapp = styled.div`
    display:block;
    font-size:24px;
    font-weight:700;
    line-height:42px;
    color:#A70500;
    text-align:left;
    width:100%;
    max-width:600px;
    text-transform: uppercase;

    @media only screen and (max-width: 1440px) {
        font-size:28px;
        line-height:25px;
    }
`

const RedText = styled.span`
  font-size:37px;
    font-weight:700;
    line-height:42px;
    color:#A70500;
    
    @media only screen and (max-width: 1440px) {
        font-size:28px;
        line-height:33px;
    }
`

const Form = ({ formData, setStep, setformData, handleChange }) => {

    const [isChecked, setisChecked] = useState(false)

    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: formData.name,
            surname: formData.surname,
            phone: formData.phone,
        }

        try {
            const response = await axios.post("https://email.kutiza.com/send-email-Wines", data);
            console.log('Response:', response.data);
            // setStep(2);
            navigate('/thanku')

        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    return (
        <FormWrapper onSubmit={handleSubmit}>
            <Grid container justifyContent={'center'} rowGap={6}>
                <Grid item>
                    <Grid container justifyContent={'start'} rowGap={2}>
                        <Grid item>
                            <TextWrapp>Offen für alle Weinliebhaber</TextWrapp>
                        </Grid>
                        <Grid item>
                            <TitleWrapp>Teilnehmen Sie an unserer Umfrage und gewinnen Sie eine Weinsammlung im Wert von <RedText>600 €</RedText>! </TitleWrapp>
                        </Grid>
                        <Grid item>
                            <Desc desc={'Vielen Dank, dass Sie an unserer Umfrage zu Weinpräferenzen teilnehmen. Ihre Rückmeldungen helfen uns dabei, eine bessere Weinauswahl für unsere Kunden zu treffen. Bitte nehmen Sie sich einen Moment Zeit, um uns Ihre Vorlieben und Erfahrungen mitzuteilen.'} />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container rowGap={1}>

                        <Grid item xs={12}>
                            <Input placeholder={'Vorname'} name={'name'} value={formData.name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Input placeholder={'Nachname'} name={'surname'} value={formData.surname} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput placeholder={'+49'} onChange={handleChange} value={formData.phone} name={'phone'} maxLength={10} minLength={10} />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckInput isChecked={isChecked} setIsChecked={setisChecked} value={formData.terms} setData={setformData} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Button text={'SENDEN'} />
                </Grid>
            </Grid>
        </FormWrapper>
    )
}

export default Form