import React, { useState } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Desc from './Desc'
import Input from './Input'
import Button from './Button'
import axios from 'axios';
import PhoneInput from './PhoneInput'
import CheckInput from './CheckInput'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as WinesRes } from '../assets/winesres.svg'

const Form = styled.form`
    width:100%;
    border-radius:8px;
    border:1px solid #E8E8E8;
    background-color:#FFFFFF;
    padding:34px 30px;
    display:flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    box-shadow: 0px 4px 16.2px 0px rgba(186, 186, 186, 0.25);
`

const TitleWrapp = styled.div`
    font-size:20px;
    font-weight:800;
    line-height:24px;
`

const TextWrapp = styled.div`
    display:block;
    font-size:14px;
    font-weight:800;
    line-height:38px;
    color:#A70500;
    text-align:left;
    width:100%;
    max-width:600px;
    text-transform: uppercase;

`

const RedText = styled.span`
  font-size:20px;
    font-weight:800;
    line-height:24px;
    color:#A70500;
`

const ResForm = ({ formData, setStep, setformData, handleChange }) => {

    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData);

        const data = {
            name: formData.name,
            surname: formData.surname,
            phone: formData.phone,
        }

        try {
            const response = await axios.post("https://email.kutiza.com/send-email-Wines", data);
            console.log('Response:', response.data);
            // setStep(2);
            navigate('/thanku')

        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };


    return (
        <Form onSubmit={handleSubmit}>
            <Grid container justifyContent={'center'} rowGap={4}>
                <Grid item>
                    <Grid container justifyContent={'start'} rowGap={1}>
                        <Grid item>
                            <TextWrapp>Offen für alle Weinliebhaber</TextWrapp>
                        </Grid>
                        <Grid item>
                            <TitleWrapp>Teilnehmen Sie an unserer Umfrage und gewinnen Sie eine Weinsammlung im Wert von <RedText>600 €</RedText>! </TitleWrapp>
                        </Grid>
                        <Grid item>
                            <Desc desc={'Vielen Dank, dass Sie an unserer Umfrage zu Weinpräferenzen teilnehmen.'} style={{ color: "#7C7C7C", textAlign: "left", fontSize: "12px", lineHeight: "20px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <WinesRes style={{ width: "100%", height: "auto"}} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container rowGap={2.5}>
                        <Grid item xs={12}>
                            <Input label={'Vorname'} placeholder={'Schreib deinen Vorname'} name={'name'} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Input label={'Nachname'} placeholder={'Schreib deinen Nachname'} name={'surname'} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput label={'Telefonnummer'} placeholder={'+49'} name={'phone'} onChange={handleChange} maxLength={10} minLength={10} />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckInput isChecked={isChecked} setIsChecked={setIsChecked} value={formData.terms} setData={setformData} />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Button text={'SENDEN'} />
                </Grid>
            </Grid>
        </Form>
    )
}

export default ResForm