import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Check } from '../assets/checkicon.svg'

const CheckBoxWrap = styled.label`
    position:relative;
    display:flex;
    align-items:start;
    padding-top:15px;
`

const Inputwrapp = styled.input`
    position:absolute;
    z-index:0;
    opacity: 0;
    pointer-events: none;
`

const Checkwrap = styled.div`
    width:15px;
    height:15px;
    border:1px solid #ccc;
    outline:none;
    background-color:#fff;
    border-radius:3px;
    positon:relative;
    margin-top:5px;
    
`

const Checkicon = styled.div`
    position:absolute;
    margin-top:5px;
    
`

const Terms = styled.div`
    font-weight:400;
    font-size:14px;
    line-height:24px;
    width:100%;
    max-width:650px;
    padding-left:10px;
    color:#7C7C7C;

    @media (max-width: 1440px) {
        font-size:12px;
         line-height:20px;
    }
    @media (max-width: 1199.98px) { 
        max-width:300px;
        font-size:12px;
    }

    @media (max-width: 899.98px) {
        max-width:300px;
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        max-width:300px;
        font-size:12px;
    }
`

const CheckInput = ({ name, value, isChecked, setIsChecked, setData }) => {

    // const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        setData(
            (prev) => ({
                ...prev,
                [name]: !isChecked
            })
        )
        setIsChecked(!isChecked);
    }

    return (

        <CheckBoxWrap>
            <Inputwrapp type="checkbox" checked={isChecked} required name={name} value={value} onChange={handleChange} />
            <Checkwrap />
            <Checkicon><Check style={{ display: isChecked ? 'block' : 'none' }} /></Checkicon>
            <Terms>Indem Sie dieses Kästchen ankreuzen, akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen*</Terms>
        </CheckBoxWrap>

    )
}

export default CheckInput