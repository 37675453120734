import React from 'react'
import { Link } from 'react-router-dom'
import 'styled-components'
import styled from 'styled-components'

const SubmitBUtton = styled.button`
    width:100%;
    background-color:#450200;
    border:none;
    padding:16px 30px;
    color:#fff;
    cursor:pointer;
    font-weight:700;
    font-size:16px;
    line-height:16px;
    
    a{
        color:#fff;
        text-decoration:none;
        width:100%;
    }

    
    @media (max-width: 899.98px) {
        padding:22px;
    }
`

const Button = ({ style, text, link, type, res }) => {
    return (
        <>
            {res ? (
                <Link to={link} style={{ width: '100%' }}>
                    <SubmitBUtton style={{ ...style }} type={type}>
                        {text}
                    </SubmitBUtton>
                </Link>
            ) : (
                <SubmitBUtton style={{ ...style }} type={type}>
                    {text}
                </SubmitBUtton>
            )}
        </>

    )
}

export default Button