import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const InputWrapp = styled.div`
    display:flex;
    border: 1px solid rgba(187, 187, 187, 0.5);
    padding: 15px 10px;
`;

const InputComponent = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size:14.1px;

    &::placeholder{
        font-size:14.1px;
        line-height:16.9px;
        font-family: 'Poppins', sans-serif;
        opacity:0.5;
    }
    

    @media only screen and (max-width: 1440px) {
        &::placeholder{
            font-size:14px;
            line-height:25px;
            opacity:0.5;
        }
    }
`;

const Label = styled.label`
    font-size: 12px;
    font-weight: 500;
    line-height: 16.9px;
    color: #00000066;
`;


const Text = styled.label`
    font-size: 12px;
    font-weight: 500;
    line-height: 16.9px;
    color: #7C7C7C;
`;


const Input = ({ placeholder, label, onChange, maxLength, minLength, name, value, type }) => {

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (maxLength && newValue.length > maxLength) {
            return;
        }
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Grid container flexDirection={'column'} rowGap={1}>
            <Text>{label}</Text>
            <Label>
                <InputWrapp>
                    <InputComponent
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        maxLength={maxLength}
                        minLength={minLength}
                    />
                </InputWrapp>
            </Label>
        </Grid>

    );
};

export default Input;
