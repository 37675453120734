import { Grid } from '@mui/material'
import React from 'react'
import Thankusvg from '../assets/thanku.svg'
import ResThankusvg from '../assets/resThanku.svg'
import styled from 'styled-components'
import useWindowDimensions from '../hooks/usewindowdimensions'

const Title = styled.div`
    font-weight:1000;
    font-size:72px;
    line-height:75px;
    width:100%;
    max-width:500px;
    color:#A70500;
    text-align:center;
    font-family: 'Roboto Flex', sans-serif;

     @media (max-width: 1440px) {
        font-size:65px;
        line-height:75px;
    }

    @media (max-width: 899.98px) {
        font-size:44px;
        line-height:38px;
        max-width:250px;
    }

    @media (max-width: 599.98px) { 
        font-size:44px;
        line-height:44px;
        max-width:290px;
    }
`

const Desc = styled.div`
    font-weight:500;
    font-size:18px;
    line-height:30px;
    width:100%;
    max-width:400px;
    color:#450200;
    text-align:center;
    
    @media (max-width: 1440px) {
        font-size:24px;
        line-height:30px;
        max-width:500px;
    }
`


const ThankU = () => {

    const { width } = useWindowDimensions();

    return (
        <Grid container flexDirection={'column'} rowGap={3} justifyContent={'center'} alignItems={'center'} style={{ backgroundImage: width > 899.99 ? `url(${Thankusvg})` : `url(${ResThankusvg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '100vh', backgroundPosition: "center" }}>
            <Grid item>
                <Title>Vielen Dank für Ihre Einreichung!  </Title>
            </Grid>
            {width > 899.99 && (
                <Grid item>
                    <Desc>Ihre Antworten wurden erfolgreich übermittelt. Wir schätzen Ihre Zeit und Ihr Feedback sehr.</Desc>
                </Grid>
            )}
        </Grid>
    )
}

export default ThankU