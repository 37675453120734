import React, { useRef } from 'react'
import Form from '../Components/Form'
import Bg from '../assets/bg.svg'
import Dotted from '../assets/dottedline.png'
import { Grid } from '@mui/material'
import { ReactComponent as Wines } from '../assets/wines.svg';
import useWindowDimensions from '../hooks/usewindowdimensions';
import ResForm from '../Components/ResForm';
import { useState } from 'react'
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import DFAForm from '../Components/DFAForm';
import styled from 'styled-components';

const Wrapper = styled(Grid)`
    justify-content:center;
    align-items:center;
    padding-left:120px;
    padding-right:80px;

    @media only screen and (max-width: 899.99px) {
        padding-left:60px;
        padding-right:0px;
    }
`

const Home = () => {

    const { width } = useWindowDimensions()

    const [Step, setStep] = useState(1)

    const [formData, setformData] = useState({
        name: '',
        surname: '',
        phone: '',
        terms: false,
        // code: ''
    });

    // const [incorrectCode, setincorrectCode] = useState('')

    // const navigate = useNavigate();

    const handleChange = (e) => {
        if (e && e.target && e.target.name) {
            const { name, value } = e.target;
            setformData({
                ...formData,
                [name]: value
            });
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const data = {
    //         name: formData.name,
    //         surname: formData.surname,
    //         phone: formData.phone,
    //         code: formData.code
    //     }

    //     try {
    //         const response = await axios.post("https://email.kutiza.com/check-code-Wines", data);
    //         console.log('Response:', response.data);
    //         navigate('/thanku')

    //     } catch (error) {
    //         setincorrectCode("Incorrect verification code!")
    //         if (error.response) {
    //             console.error('Server Error:', error.response.data);
    //             console.error('Status Code:', error.response.status);
    //             console.error('Headers:', error.response.headers);
    //         } else if (error.request) {
    //             console.error('No response received:', error.request);
    //         } else {
    //             console.error('Error:', error.message);
    //         }
    //         console.error('Error config:', error.config);
    //     }
    // };

    const stepsRef = useRef(null);

    return (
        <div style={{
            backgroundImage: `url(${Bg})`, backgroundSize: width < 899.99 ? 'contain' : "contain", backgroundRepeat: 'no-repeat',
            minHeight: '100vh', display: "flex", justifyContent: "center", alignItems: "center",
            paddingTop: (width < 1200 && width > 900) ? "200px" : (width < 900) ? "30px" : "0px",
            paddingBottom: (width < 1200 && width > 900) ? "200px" : (width < 900) ? "20px" : "0px"
        }}>
            <div style={{
                backgroundImage: (width < 899.99 && Step === 1) && `url(${Dotted})`, backgroundSize: width < 899.99 ? 'cover' : "contain", backgroundRepeat: 'no-repeat',
                minHeight: '100vh', display: "flex", justifyContent: "center", alignItems: "center",
                paddingTop: (width < 1200 && width > 900) ? "200px" : (width < 900) ? "30px" : "0px",
                paddingBottom: (width < 1200 && width > 900) ? "200px" : (width < 900) ? "30px" : "0px"
            }}>
                <Wrapper container rowSpacing={width > 899.99 && 6} columnSpacing={7}>
                    {width > 899.99 ? (
                        <Grid item xs>
                            <div ref={stepsRef}></div>
                            {
                                {
                                    1: <Form formData={formData} setformData={setformData} setStep={setStep} handleChange={handleChange} />,
                                    2: <DFAForm formData={formData} setFormData={setformData} />
                                }[Step]
                            }

                        </Grid>
                    ) : (
                        <Grid container justifyContent={'center'} alignItems={'flex-start'} maxWidth={'380px'} >
                            <div ref={stepsRef}></div>
                            {
                                {
                                    1: <ResForm formData={formData} setformData={setformData} setStep={setStep} handleChange={handleChange} />,
                                    2: <DFAForm formData={formData} setFormData={setformData} />
                                }[Step]
                            }
                        </Grid>
                    )}

                    {(Step === 1 && width > 899.99) && (
                        <Grid item xs>
                            <Wines style={{ width: "100%", height: "auto", maxWidth: width <= 899.99 ? "350px" : width > 1440 ? "700px" : "570px" }} />
                        </Grid>
                    )}
                </Wrapper>
            </div>
        </div >
    )
}

export default Home