import React from 'react'
import styled from 'styled-components';
import Button from '../Components/Button';
import ReactCodeInput from 'react-code-input';

const FormWrapper = styled.form`
    width:100%;
    max-width:650px;
    border-radius:2px;
    border:1px solid #FFFFFF;
    background-color:#FFFFFF;
    padding:30px 40px;
    display:flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin:auto;

    @media only screen and (max-width: 1440px) {
        max-width:500px;
    }

     @media only screen and (max-width: 899.99px) {
        max-width:300px;
    }
`

const TitleWrapp = styled.div`
    display:block;
    font-size:41px;
    font-weight:800;
    line-height:22px;
    color:#000000;
    text-align:center;
    width:100%;
    padding-bottom:30px;


    @media only screen and (max-width: 1440px) {
        font-size:20px;

        line-height:32px;
    }
`

const Resendcode = styled.div`
    display:block;
    font-size:14px;
    font-weight:400;
    line-height:24px;
    color:#7C7C7C;
    text-align:center;
    width:100%;
    padding-bottom:70px;

    span{
        font-weight:800;
        color:#450200;
    }

    @media (max-width: 1440px) {
        font-size:12px;
    }

    @media (max-width: 899.98px) {
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
        padding-bottom:50px;
    }

    @media (max-width: 599.98px) { 
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
    }
`

const IncorrectCode = styled.div`
    display:block;
    font-size:20px;
    font-weight:500;
    line-height:24px;
    color:#450200;
    text-align:center;
    width:100%;
    padding-bottom:100px;

    @media (max-width: 1440px) {
        font-size:12px;
    }

    @media (max-width: 899.98px) {
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
        padding-bottom:50px;
    }

    @media (max-width: 599.98px) { 
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
    }
`

const DescWrapp = styled.div`
    display:block;
    font-size:14px;
    font-weight:400;
    line-height:24px;
    color:#7C7C7C;
    text-align:center;
    width:100%;
    max-width:480px;
    padding-bottom:50px;

    @media (max-width: 1440px) {
        font-size:12px;
    }

    @media (max-width: 899.98px) {
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
        padding-bottom:50px;
    }

    @media (max-width: 599.98px) { 
        font-weight:400;
        font-size:12px;
        line-height:18px;
        max-width:300px;
    }
`

const DFAForm = ({ formData, setFormData, handleSubmit, incorrectCode }) => {

    const handleChange = (value) => {
        setFormData({
            ...formData,
            code: value
        });
    };

    return (
        <FormWrapper onSubmit={handleSubmit}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div style={{paddingTop:"40px"}}>
                    <TitleWrapp>Verification Code Notification</TitleWrapp>
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <DescWrapp>
                            A verification code has been sent to your email or SMS. Please enter the code below to verify. If you didn't receive it, click "Resend Code." Thank you!
                        </DescWrapp>
                    </div>
                </div>
                <div style={{ paddingBottom: "20px", display: "flex", justifyContent: "center" }}>
                    <ReactCodeInput type='text' fields={6} onChange={handleChange} value={formData.code} name='code' />
                </div>
                {incorrectCode && (
                    <IncorrectCode>{incorrectCode}</IncorrectCode>
                )}
                <div>
                    <Resendcode>Did you receive your code? <span onClick={handleSubmit}>Resend Code</span></Resendcode>
                </div>
                <div style={{paddingBottom:"10px"}}>
                    <Button type={'submit'} text={'CONFIRM'} />
                </div>
            </div>
        </FormWrapper>
    )
}

export default DFAForm