import { Grid } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

const InputWrapp = styled.div`
    border: 1px solid rgba(187, 187, 187, 0.5);
    padding: 15px 22px;
`;

const InputComponent = styled.input`
    width: 100%;
    border: none;
    outline: none;
    padding-left: 0px !important;
    font-size:14.1px;
     
      &::placeholder{
            font-size:14.1px;
            line-height:16.9px;
            font-family: 'Poppins', sans-serif;
            opacity:0.5;
        }
`;

const Label = styled.label`
    font-size: 12px;
    font-weight: 500;
    line-height: 16.9px;
    color: #00000066;
`;

const Text = styled.label`
    font-size: 12px;
    font-weight: 500;
    line-height: 16.9px;
    color: #7C7C7C;
`;

const PhoneInput = ({ placeholder, label, onChange, value, name, setFormData, formData }) => {
    const [inputValue, setInputValue] = useState(value || "+49");

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue); // Update the input value
        if (onChange) onChange(event);
    };

    const handleBlur = () => {
        if (!inputValue.startsWith("+49")) {
            setInputValue("+49");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Backspace" && event.target.selectionStart === 0 && inputValue.slice(3) === "") {
            event.preventDefault();
        }
    };

    return (
        <Grid container flexDirection={'column'} rowGap={1}>
            <Text> {label}</Text>
            <Label>

                <InputWrapp>
                    <InputComponent
                        // pattern="^\+49\d{2,3}\s?\d{4,5}\s?\d{4}$"
                        minLength={12}
                        maxLength={12}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        autoComplete='new-password'
                        name={name}
                        value={inputValue}
                        placeholder={placeholder}
                        style={{ border: "none", paddingLeft: "10px", outline: "none" }}
                    />
                </InputWrapp>
            </Label>
        </Grid>

    );
};

export default PhoneInput;
